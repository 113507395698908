



























































.accommodation-wrp {
  width: 100%;
  height: auto;
  min-height: 320;
  margin: auto;
  border-radius: 25px;
  box-shadow: 0 1rem 1.5rem rgba(0, 0, 0, 0.5);
}

.no-data-container {
  margin: auto;
  padding: 25px 0;
}

.accommodation-header {
  position: relative;
  height: 11rem;
  background: #008489;
  border-radius: 25px 25px 0 0;
  widows: 100%;
  h2 {
    position: absolute;
    bottom: 10px;
    left: 10px;
    margin-bottom: 0;
    color: white;
  }
}
.accommodation-body {
  display: flex;
  width: 100%;
  padding: 0 25px;
  padding-bottom: 25px;
  span {
    flex: 1 auto;
    justify-content: space-between;
  }
  h3 {
    margin-top: 10px;
    color: #008489;
    font-weight: 500;
    font-size: 18px;
  }
}
#datepicker-trigger {
  display: none !important;
}

.asd__action-buttons {
  display: none;
}

.asd__wrapper {
  border: none;
  border-radius: 10px;
  box-shadow: 2px 2px 10px #70707040;
}
