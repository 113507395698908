












































































































































.booking-image {
  width: 200px;
  background-size: cover;
  border-radius: 10px 0px 0px 10px;
}

.booking-txt {
  div {
    margin: 5px 0px;
  }
}

.booking-wrp {
  position: relative;
  display: flex;
  margin: 15px 0px;
  border-radius: 10px;
  box-shadow: 2px 2px 10px #70707040;
  .booking-view-more-btn {
    position: absolute;
    bottom: -10px;
    left: 50%;
    width: 133px;
    padding: 3px;
    background: #f2f2f2 0% 0% no-repeat padding-box;
    border: 1px solid #b1b1b1;
    border-radius: 25px;
    box-shadow: 0px 2px 6px #00000029;
    transform: translateX(-50%);
    .svg-inline--fa {
      margin-left: 10px;
    }
  }
  .formulate-input-element--submit {
    margin-top: 28px;
  }
  .formulate-input {
    flex-grow: 1;
    margin-right: 10px !important;
  }
  .formulate-input[data-classification='button'] button {
    width: 100%;
    padding: 0.3em;
  }
}

.booking-info-container {
  position: relative;
  display: flex;
  flex-grow: 1;
  padding: 15px 25px;
  overflow: visible;
  h3 {
    margin-bottom: 10px;
    color: #707070;
    font-weight: 500;
    font-size: 18px;
  }
  .svg-inline--fa {
    min-width: 20px;
  }
}

.booking-time {
  margin-left: auto;
  div {
    margin-bottom: 5px;
  }
}

.formulate-input-wrapper {
  span {
    margin-left: 5px;
    pointer-events: none;
  }
}

// Most important part
.expand {
  overflow: hidden;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); // Timing functions also!
  transition-duration: 0.4s; // Durations can be changed without touching JS
  transition-property: height;

  &[aria-expanded='false'] {
    height: 0 !important;
    transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045); // Timing function can be different for each direction
  }
}

.u-no-transition {
  transition-duration: 0s !important;
}
